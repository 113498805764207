import Vue from 'vue'
import auth from '@auth'
import VueRouter from 'vue-router'
import authRoute from './routes/auth'
import errorRoute from './routes/error'
import dashboardRoute from './routes/dashboard'
import personInfoRoute from './routes/personInfo'
import reminderEventRoute from './routes/reminderEvent'
import shopeeVoucherRoute from './routes/shopeeVoucher'
import numerologyRoute from './routes/numerology'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...authRoute,
    ...errorRoute,
    ...dashboardRoute,
    ...personInfoRoute,
    ...reminderEventRoute,
    ...shopeeVoucherRoute,
    ...numerologyRoute,
  ],
})

router.beforeEach(async (to, from, next) => {
  const loggedIn = !!auth.getToken()
  const requireAuth = to.matched.some(record => record.meta.requireAuth)
  const requireNotAuth = to.matched.some(record => record.meta.requireNotAuth)

  if (loggedIn) {
    if (requireNotAuth) {
      next({ name: 'dashboard' })
    }
  } else if (requireAuth) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  Vue.nextTick(() => {
    document.title = to.meta.pageTitle || this.$commonVar.APP_NAME
  })
})

export default router
