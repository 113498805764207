import { PersonInfoService } from '@/services'

export default {
  async getPersonInfos({ commit }, params) {
    commit('GET_PERSON_INFOS_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.getPersonInfos(params)

      commit('GET_PERSON_INFOS_SUCCESS', data)
    } catch (error) {
      commit('GET_PERSON_INFOS_FAILURE', error)
    }
  },
  async getPersonInfo({ commit }, id) {
    commit('GET_PERSON_INFO_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.getPersonInfo(id)

      commit('GET_PERSON_INFO_SUCCESS', data)
    } catch (error) {
      commit('GET_PERSON_INFO_FAILURE', error)
    }
  },
  async getRelationships({ commit }, params) {
    commit('GET_RELATIONSHIPS_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.getRelationships(params)

      commit('GET_RELATIONSHIPS_SUCCESS', data)
    } catch (error) {
      commit('GET_RELATIONSHIPS_FAILURE', error)
    }
  },
  async createPersonInfo({ commit }, params) {
    commit('CREATE_PERSON_INFO_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.createPersonInfo(params)

      commit('CREATE_PERSON_INFO_SUCCESS', data)
    } catch (error) {
      commit('CREATE_PERSON_INFO_FAILURE', error)
    }
  },
  async updatePersonInfo({ state, commit }, params) {
    commit('UPDATE_PERSON_INFO_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.updatePersonInfo(state.personInfo.id, params)

      commit('UPDATE_PERSON_INFO_SUCCESS', data)
    } catch (error) {
      commit('UPDATE_PERSON_INFO_FAILURE', error)
    }
  },
  async deletePersonInfo({ commit }, params) {
    commit('DELETE_PERSON_INFO_REQUEST')
    try {
      const {
        data: { data },
      } = await PersonInfoService.deletePersonInfo(params)

      commit('DELETE_PERSON_INFO_SUCCESS', data)
    } catch (error) {
      commit('DELETE_PERSON_INFO_FAILURE', error)
    }
  },
  async downloadPersonInfo({ commit }, params) {
    commit('DOWNLOAD_PERSON_INFO_CSV_REQUEST')
    try {
      await PersonInfoService.downloadPersonInfo(params)

      commit('DOWNLOAD_PERSON_INFO_CSV_SUCCESS')
    } catch (error) {
      commit('DOWNLOAD_PERSON_INFO_CSV_FAILURE', error)
    }
  },
}
