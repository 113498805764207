import Vue from 'vue'
import { format } from 'date-fns'

Vue.filter('capitalize', str => {
  if (!str) return ''
  const value = str.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDate', value => (value
  ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : ''))

Vue.filter('noExponents', value => {
  const data = String(value).split(/[eE]/)
  if (data.length === 1) return data[0]

  let z = ''; const sign = this < 0 ? '-' : ''
  const str = data[0].replace('.', '')
  let mag = Number(data[1]) + 1

  if (mag < 0) {
    z = `${sign}0.`
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0'
    return z + str.replace(/^-/, '')
  }
  mag -= str.length
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0'
  return str + z
})
