import { AuthService } from '@/services'
import auth from '@auth'

export default {
  async login({ commit }, { params }) {
    commit('LOGIN_REQUEST')
    try {
      const { data: { data: { token } } } = await AuthService.login(params)
      auth.setToken(token)
      commit('LOGIN_SUCCESS', token)
    } catch (error) {
      commit('LOGIN_FAILURE', error)
    }
  },

  logout({ commit }) {
    auth.clearLocalStorage()
    commit('LOGOUT')
  },
}
