import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_DASHBOARD_REQUEST(state) {
    REQUEST(state)
  },
  GET_DASHBOARD_SUCCESS(state, data) {
    SUCCESS(state)
    state.dashboard = data.data
  },
  GET_DASHBOARD_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_REPORT_DAILY_REQUEST(state) {
    // REQUEST(state)
  },
  GET_REPORT_DAILY_SUCCESS(state) {
    SUCCESS(state)
  },
  GET_REPORT_DAILY_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_PROCESS_EVENT_REQUEST(state) {
    // REQUEST(state)
  },
  GET_PROCESS_EVENT_SUCCESS(state) {
    SUCCESS(state)
  },
  GET_PROCESS_EVENT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_SHOPEE_VOUCHERS_REQUEST(state) {
    // REQUEST(state)
  },
  GET_SHOPEE_VOUCHERS_SUCCESS(state) {
    SUCCESS(state)
  },
  GET_SHOPEE_VOUCHERS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_RESET_DATA_REQUEST(state) {
    // REQUEST(state)
  },
  GET_RESET_DATA_SUCCESS(state) {
    SUCCESS(state)
  },
  GET_RESET_DATA_FAILURE(state, error) {
    FAILURE(state, error)
  },

}
