import { ShopeeVoucherService } from '@/services'

export default {
  async getShopeeVouchers({ commit }, params) {
    commit('GET_SHOPEE_VOUCHERS_REQUEST')
    try {
      const {
        data,
      } = await ShopeeVoucherService.getShopeeVouchers(params)

      commit('GET_SHOPEE_VOUCHERS_SUCCESS', data)
    } catch (error) {
      commit('GET_SHOPEE_VOUCHERS_FAILURE', error)
    }
  },
}
