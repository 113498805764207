import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import VueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'
import '@/filters/filters'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import router from './router'
import store from './store'
import App from './App.vue'

// Common function
import commonFunction from './common-function'
import commonVariable from './common-variable'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import './registerServiceWorker'

Vue.prototype.$commonFunc = commonFunction
Vue.prototype.$commonVar = commonVariable

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// VueClipboard
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueDebounce)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
