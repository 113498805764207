import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_SHOPEE_VOUCHERS_REQUEST(state) {
    REQUEST(state)
  },
  GET_SHOPEE_VOUCHERS_SUCCESS(state, data) {
    SUCCESS(state)
    state.shopeeVouchers.data = data.data
    state.shopeeVouchers.total = data.total
  },
  GET_SHOPEE_VOUCHERS_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
