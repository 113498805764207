/* eslint-disable import/prefer-default-export */
export const removeItemByIndex = (items, i) => items.slice(0, i).concat(items.slice(i + 1, items.length))

export const showLoading = () => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'block'
    appLoading.style.position = 'fixed'
  }
}

export const hideLoading = () => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
}

export const checkFieldValueExists = (field, value, array) => {
  const isExist = array.find(item => item[field] === value)
  return !!isExist
}

export const checkSizeFile = ({ value, maxValue, type }) => {
  let maxValueTyped = 0
  let pow = 0
  switch (type) {
    case 'kb': pow = 1; break
    case 'mb': pow = 2; break
    default:
  }

  maxValueTyped = maxValue * (1024 ** pow)

  return value <= maxValueTyped
}
