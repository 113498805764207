import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_PERSON_INFOS_REQUEST(state) {
    REQUEST(state)
  },
  GET_PERSON_INFOS_SUCCESS(state, data) {
    SUCCESS(state)
    state.personInfos.data = data.data
    state.personInfos.total = data.total
  },
  GET_PERSON_INFOS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_PERSON_INFO_REQUEST(state) {
    REQUEST(state)
  },
  GET_PERSON_INFO_SUCCESS(state, data) {
    SUCCESS(state)
    state.personInfo = {
      ...data,
      relationships: [],
    }
  },
  GET_PERSON_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_RELATIONSHIPS_REQUEST(state) {
    REQUEST(state)
  },
  GET_RELATIONSHIPS_SUCCESS(state, data) {
    SUCCESS(state)
    state.relationships = data.map(item => item.name)
  },
  GET_RELATIONSHIPS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  CREATE_PERSON_INFO_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_PERSON_INFO_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_PERSON_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPDATE_PERSON_INFO_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_PERSON_INFO_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_PERSON_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  DELETE_PERSON_INFO_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_PERSON_INFO_SUCCESS(state) {
    SUCCESS(state)
  },
  DELETE_PERSON_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  DOWNLOAD_PERSON_INFO_CSV_REQUEST(state) {
    REQUEST(state)
  },
  DOWNLOAD_PERSON_INFO_CSV_SUCCESS(state) {
    SUCCESS(state)
  },
  DOWNLOAD_PERSON_INFO_CSV_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
