import axios from '@axios'

export default {
  getRelationships() {
    return axios.get('/api/relationships/all')
  },
  getPersonInfos(params) {
    return axios.get('/api/person-infos', { params })
  },
  getPersonInfo(id) {
    return axios.get(`/api/person-infos/${id}`)
  },
  createPersonInfo(params) {
    return axios.post('/api/person-infos', params)
  },
  updatePersonInfo(id, params) {
    return axios.put(`/api/person-infos/${id}`, params)
  },
  deletePersonInfo(id) {
    return axios.delete(`/api/person-infos/${id}`)
  },
  downloadPersonInfo() {
    return axios.get('/api/person-infos/download-csv').then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'PersonInfo.csv') // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
