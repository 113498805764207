import { NumerologyService } from '@/services'

export default {
  async getNumerologies({ commit }, params) {
    commit('GET_NUMEROLOGIES_REQUEST')
    try {
      const numerologyStorage = localStorage.getItem('numerology')
      if (!numerologyStorage) {
        const { data } = await NumerologyService.getNumerologies(params)

        localStorage.setItem('numerology', JSON.stringify(data.data))
      }
      commit('GET_NUMEROLOGIES_SUCCESS', JSON.parse(localStorage.getItem('numerology')))
    } catch (error) {
      commit('GET_NUMEROLOGIES_FAILURE', error)
    }
  },
}
