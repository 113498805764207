import Vue from 'vue'

// axios
import axios from 'axios'
import auth from './auth'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_SERVER_API,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.getToken()}`,
  },
})

axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = auth.getToken()

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => {
    throw error.response
  },
)

// throw error when call aixos error
axiosIns.interceptors.response.use(null, error => {
  if (error.response.status === 403) {
    auth.removeToken()
    window.location = '/login'
  }
  throw error.response
})

Vue.prototype.$http = axiosIns

export default axiosIns
