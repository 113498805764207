import axios from '@axios'

const baseCommandsURL = '/api/commands'

export default {
  getDashboard() {
    return axios.get('/api/dashboard')
  },
  reportDaily() {
    return axios.get(`${baseCommandsURL}/report-daily`)
  },
  processEvent() {
    return axios.get(`${baseCommandsURL}/process-events`)
  },
  shopeeVouchers() {
    return axios.get(`${baseCommandsURL}/get-shopee-vouchers`)
  },
  resetData() {
    return axios.get(`${baseCommandsURL}/reset-data`)
  },
}
