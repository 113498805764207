import { isNaN } from 'lodash'

const weekDaysFromNow = day => {
  if (isNaN(day)) {
    return ''
  }
  const weekday = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']

  const date = new Date()
  date.setDate(date.getDate() + (day % 7))
  return weekday[date.getDay()]
}

const weekDays = day => {
  if (isNaN(day)) {
    return ''
  }
  day.setHours(0, 0, 0, 0)
  const weekday = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
  return weekday[day.getDay()]
}

const leftDaysForHuman = day => {
  switch (day) {
    case 0:
      return 'Hôm nay'

    case 1:
      return 'Ngày mai'

    default:
      return day
  }
}

const minTwoDigits = number => (number < 10 ? '0' : '') + number

const diffDate = (fromDate, toDate) => {
  fromDate.setHours(0, 0, 0, 0)
  toDate.setHours(0, 0, 0, 0)
  const diff = fromDate > toDate ? fromDate.getTime() - toDate.getTime() : toDate.getTime() - fromDate.getTime()
  return new Date(diff).getUTCDate() - 1
}

const diffDateForHuman = date => {
  const startDate = new Date(date)
  const endDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)
  const diffInDate = new Date(startDate > endDate ? startDate - endDate : endDate - startDate)
  return (`${diffInDate.toISOString().slice(0, 4) - 1970}y ${
    diffInDate.getMonth()}m ${diffInDate.getDate() - 1}d`)
}

const today = () => (new Date()).setHours(0, 0, 0, 0)

const createReminderEvent = (year, month, day, type) => {
  if (day && month && year && type) {
    if (type === 3) {
      return new Date(year, month - 1, day)
    }
    const tempEventDate = new Date((new Date()).getFullYear(), month - 1, day)
    if (tempEventDate < today()) {
      tempEventDate.setFullYear(tempEventDate.getFullYear() + 1)
    }
    return tempEventDate
  }
  return null
}

export default {
  weekDaysFromNow,
  leftDaysForHuman,
  minTwoDigits,
  diffDateForHuman,
  diffDate,
  weekDays,
  today,
  createReminderEvent,
}
