import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    status: false,
    error: null,
    message: '',
    loading: false,
    dashboard: null,
  },
  getters,
  mutations,
  actions,
}
