import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_NUMEROLOGIES_REQUEST(state) {
    REQUEST(state)
  },
  GET_NUMEROLOGIES_SUCCESS(state, data) {
    SUCCESS(state)
    state.numerologies = data
  },
  GET_NUMEROLOGIES_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
