export default [
  {
    path: '/person-infos',
    name: 'person-infos',
    component: () => import('@/views/personInfo/PersonInfo.vue'),
    meta: {
      pageTitle: 'Person Infos',
      requireAuth: true,
    },
  },
  {
    path: '/person-infos/create',
    name: 'create-person-infos',
    component: () => import('@/views/personInfo/CreatePersonInfo.vue'),
    meta: {
      pageTitle: 'Create Person Infos',
      requireAuth: true,
    },
  },
  {
    path: '/person-infos/:id',
    name: 'update-person-infos',
    component: () => import('@/views/personInfo/UpdatePersonInfo.vue'),
    meta: {
      pageTitle: 'Update Person Infos',
      requireAuth: true,
    },
  },
]
