import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // login
  LOGIN_REQUEST(state) {
    REQUEST(state)
  },
  LOGIN_SUCCESS(state, idToken) {
    SUCCESS(state)
    state.idToken = idToken
    state.isLoggedIn = true
  },
  LOGIN_FAILURE(state, error) {
    FAILURE(state, error)
    state.isLoggedIn = false
  },

  // logout
  LOGOUT(state) {
    state.isLoggedIn = false
    state.idToken = ''
  },
}
