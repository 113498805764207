export default [
  {
    path: '/reminder-events',
    name: 'reminder-events',
    component: () => import('@/views/reminderEvent/ReminderEvent.vue'),
    meta: {
      pageTitle: 'Reminder Events',
      requireAuth: true,
    },
  },
  {
    path: '/reminder-events/create',
    name: 'create-reminder-events',
    component: () => import('@/views/reminderEvent/CreateReminderEvent.vue'),
    meta: {
      pageTitle: 'Create Reminder Events',
      requireAuth: true,
    },
  },
  {
    path: '/reminder-events/:id',
    name: 'update-reminder-events',
    component: () => import('@/views/reminderEvent/UpdateReminderEvent.vue'),
    meta: {
      pageTitle: 'Update Reminder Events',
      requireAuth: true,
    },
  },
]
