import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_REMINDER_EVENT_REQUEST(state) {
    REQUEST(state)
  },
  GET_REMINDER_EVENT_SUCCESS(state, data) {
    SUCCESS(state)
    state.reminderEvent = { ...data }
  },
  GET_REMINDER_EVENT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_REMINDER_EVENTS_REQUEST(state) {
    REQUEST(state)
  },
  GET_REMINDER_EVENTS_SUCCESS(state, data) {
    SUCCESS(state)
    state.reminderEvents.data = data.data
    state.reminderEvents.total = data.total
  },
  GET_REMINDER_EVENTS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  CREATE_REMINDER_EVENTS_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_REMINDER_EVENTS_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_REMINDER_EVENTS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPDATE_REMINDER_EVENT_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_REMINDER_EVENT_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_REMINDER_EVENT_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
