import { ReminderEventService } from '@/services'

export default {
  async getReminderEvents({ commit }, params) {
    commit('GET_REMINDER_EVENTS_REQUEST')
    try {
      const {
        data: { data },
      } = await ReminderEventService.getReminderEvents(params)

      commit('GET_REMINDER_EVENTS_SUCCESS', data)
    } catch (error) {
      commit('GET_REMINDER_EVENTS_FAILURE', error)
    }
  },
  async getReminderEvent({ commit }, id) {
    commit('GET_REMINDER_EVENT_REQUEST')
    try {
      const {
        data: { data },
      } = await ReminderEventService.getReminderEvent(id)

      commit('GET_REMINDER_EVENT_SUCCESS', data)
    } catch (error) {
      commit('GET_REMINDER_EVENT_FAILURE', error)
    }
  },
  async createReminderEvents({ commit }, params) {
    commit('CREATE_REMINDER_EVENTS_REQUEST')

    try {
      const {
        data: { data },
      } = await ReminderEventService.createReminderEvents(params)

      commit('CREATE_REMINDER_EVENTS_SUCCESS', data)
    } catch (error) {
      commit('CREATE_REMINDER_EVENTS_FAILURE', error)
    }
  },
  async updateReminderEvent({ state, commit }, params) {
    commit('UPDATE_REMINDER_EVENT_REQUEST')
    try {
      const {
        data: { data },
      } = await ReminderEventService.updateReminderEvent(state.reminderEvent.id, params)

      commit('UPDATE_REMINDER_EVENT_SUCCESS', data)
    } catch (error) {
      commit('UPDATE_REMINDER_EVENT_FAILURE', error)
    }
  },
}
