import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { required, regex } from '@validations'
import Cleave from 'vue-cleave-component'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('BCardCode', BCardCode)

Vue.component('vSelect', vSelect)

Vue.component('VueGoodTable', VueGoodTable)

Vue.component('required', required)
Vue.component('regex', regex)

Vue.component('Cleave', Cleave)
