export default [
  {
    path: '/shopee-vouchers',
    name: 'shopee-vouchers',
    component: () => import('@/views/shopeeVoucher/ShopeeVoucher.vue'),
    meta: {
      pageTitle: 'Shopee vouchers',
      requireAuth: true,
    },
  },
]
