import axios from '@axios'

export default {
  getReminderEvent(id) {
    return axios.get(`/api/reminder-events/${id}`)
  },
  getReminderEvents(params) {
    return axios.get('/api/reminder-events', { params })
  },
  createReminderEvents(params) {
    return axios.post('/api/reminder-events', params)
  },
  updateReminderEvent(id, params) {
    return axios.put(`/api/reminder-events/${id}`, params)
  },
}
