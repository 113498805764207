import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import dashboard from './dashboard'
import personInfo from './person-info'
import reminderEvent from './reminder-event'
import shopeeVoucher from './shopee-voucher'
import numerologies from './numerologies'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    dashboard,
    personInfo,
    reminderEvent,
    shopeeVoucher,
    numerologies,
  },
  strict: process.env.DEV,
})
