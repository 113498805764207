import { DashboardService } from '@/services'

export default {
  async getDashboard({ commit }, params) {
    commit('GET_DASHBOARD_REQUEST')
    try {
      const { data } = await DashboardService.getDashboard(params)
      commit('GET_DASHBOARD_SUCCESS', data)
    } catch (error) {
      commit('GET_DASHBOARD_FAILURE', error)
    }
  },
  async reportDaily({ commit }, params) {
    commit('GET_REPORT_DAILY_REQUEST')
    try {
      const { data } = await DashboardService.reportDaily(params)
      commit('GET_REPORT_DAILY_SUCCESS', data)
    } catch (error) {
      commit('GET_REPORT_DAILY_FAILURE', error)
    }
  },
  async processEvent({ commit }, params) {
    commit('GET_PROCESS_EVENT_REQUEST')
    try {
      const { data } = await DashboardService.processEvent(params)
      commit('GET_PROCESS_EVENT_SUCCESS', data)
    } catch (error) {
      commit('GET_PROCESS_EVENT_FAILURE', error)
    }
  },
  async shopeeVouchers({ commit }, params) {
    commit('GET_SHOPEE_VOUCHERS_REQUEST')
    try {
      const { data } = await DashboardService.shopeeVouchers(params)
      commit('GET_SHOPEE_VOUCHERS_SUCCESS', data)
    } catch (error) {
      commit('GET_SHOPEE_VOUCHERS_FAILURE', error)
    }
  },
  async resetData({ commit }, params) {
    commit('GET_RESET_DATA_REQUEST')
    try {
      const { data } = await DashboardService.resetData(params)
      commit('GET_RESET_DATA_SUCCESS', data)
    } catch (error) {
      commit('GET_RESET_DATA_FAILURE', error)
    }
  },
}
